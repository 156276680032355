import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { isNullOrUndefined } from 'util';

@Injectable()
export class AdminService {
    selectedUser: any;
    searchText = '';
    constructor(private http: HttpClient) { }

    validateEmailExists(email: string): Observable<boolean> {
        let canCall = true;
        if (!isNullOrUndefined(this.selectedUser)) {
            canCall = (this.selectedUser.email !== email);
        }
        if (canCall === true) {
            return this.http.get<boolean>('account/userExists?email=' + email).map(res => res);
        } else {
            return Observable.of(false);
        }
    }

    createUser(user: any): any {
        if (user.IsAllStore === true && user.AssignedStore.length > 1) {
            user.AssignedStore = [0];
        }
        if (user.asAllStore === true && user.assignedStore.length > 1) {
            user.assignedStore = [0];
        }
        if (user.userId > 0) {
            user.username = user.email;
            return this.http.post<any>('account/update', user);
        } else {
            user.Username = user.Email;
            return this.http.post<any>('account/create', user);
        }
    }

    getUserList(): any {
        return this.http.get<any>('account/users');
    }

    getUserDetail(id): any {
        return this.http.get<any>('account/userdetail?userId=' + id);
    }

    getGetDefaultDashboardData(filter: any): any {
        return this.http.get<any>('sales/defaultdashboardgraphdata');
    }

    advanceStoreSearch(filter: any): any {
        return this.http.post<any[]>('store/advancesearchstore', filter);
    }
}

// @Injectable()
// export class FileDatabase {
//     dataChange = new BehaviorSubject<FileNode[]>([]);
//     get data(): FileNode[] { return this.dataChange.value; }
//     constructor() {
//         this.initialize();
//     }
//     initialize() {
//          Parse the string to json object.
//          const dataObject = JSON.parse(TREE_DATA);
//          Build the tree nodes from Json object. The result is a list of `FileNode` with nested
//          file node as children.
//          const data = this.buildFileTree(dataObject, 0);
//          const data = TREE_DATA3;
//          const data = this.buildFileTree(JSON.parse(TREE_DATA2), 0);
//          console.log(JSON.stringify(data));
//          Notify the change.
//          this.dataChange.next(TREE_DATA3);
//     }
//     /**
//      * Build the file structure tree. The `value` is the Json object, or a sub-tree of a Json object.
//      * The return value is the list of `FileNode`.
//      */
//     // buildFileTree(obj: { [key: string]: any }, level: number): FileNode[] {
//     //     return Object.keys(obj).reduce<FileNode[]>((accumulator, key) => {
//     //         const value = obj[key];
//     //         const node = new FileNode();
//     //         node.filename = key;
//     //         if (value != null) {
//     //             if (typeof value === 'object') {
//     //                 node.children = value;
//     //                 //  = this.buildFileTree(value, level + 1);
//     //             } else {
//     //                 node.type = value;
//     //             }
//     //         }
//     //         return accumulator.concat(node);
//     //     }, []);
//     // }
// }

// // public class Child
// // {
// //     public int id { get; set; }
// //     public int parentId { get; set; }
// //     public string title { get; set; }
// //     public string link { get; set; }
// //     public string icon { get; set; }
// //     public bool? home { get; set; }
// //     public int userAccessLevel { get; set; }
// // }

// // public class RootObject
// // {
// //     public string filename { get; set; }
// //     public int id { get; set; }
// //     public int parentId { get; set; }
// //     public string title { get; set; }
// //     public object link { get; set; }
// //     public string icon { get; set; }
// //     public object home { get; set; }
// //     public int userAccessLevel { get; set; }
// //     public List<Child> children { get; set; }
// // }